<template>
  <v-row class="ml-7" style="width: 96%">
    <v-col>
      <template>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="users"
            :loading="loading"
            loading-text="Loading... Please wait"
            no-data-text="No user available"
            item-key="id"
            class="elevation-1"
            style="background: #f5f5f5"
          >
            <template v-slot:top>
              <v-toolbar
                elevation="2"
                style="background: #363636; font-size: 26px"
              >
                <p class="mb-0 white--text">Delivery Men</p>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn color="primary" style="padding-left: 0px !important" text
                >Details</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "User ID",
          align: "start",
          value: "id",
        },
        {
          text: "Name",
          align: "start",
          value: "username",
        },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phone_number", sortable: false },
        { text: "Assigned Orders", value: "actions", sortable: false },
      ],
      users: [],
      loading: true,
    };
  },
  methods: {
    initialize() {
      axios
        .get(`user/show_all_delivery_personnel/`)
        .then((response) => {
          this.loading = false;
          // console.log({ response });
          if (response.data.success) {
            this.users = response.data.data;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>